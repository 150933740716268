import qs from 'qs'
import service from '../request/request'



// 用户登录
export function Login(data){
  return service({url:'/api/public/login',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//    退出
export function login_out(data){
  return service({url:'/api/public/loginOut',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

// 侧边导航权限 
export function nav_power(data){
  return service({url:'/api/public_permission/mobilePermission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 按钮权限 
export function button_power(data){
  return service({url:'/api/public_permission/getButtonPermissionById',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}




// 用户管理  表格
export function user(data){
  return service({url:'/api/user/getUser',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
export function user_index(data){
  return service({url:'/api/user/getUserIndex',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 状态  停
export function user_stop(data){
  return service({url:'/api/user/deleteUser',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//      启
export function user_open(data){
  return service({url:'/api/user/enable',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 添加
export function user_add(data){
  return service({url:'/api/user/addUser',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 获取某个用户数据
export function user_compile(data){
  return service({url:'/api/user/getUserById',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 编辑
export function user_compiles(data){
  return service({url:'/api/user/modifyUser',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

//   角色选择 
export function user_father(data){
  return service({url:'/api/user/getRoleOption',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

//    渠道
export function user_channel(data){
  return service({url:'/api/user/get_all_media',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 是否具有增加权限 
export function user_add_power(data){
  return service({url:'/api/user/check_add_permission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}







// 角色管理
export function role(data){
  return service({url:'/api/role/getRole',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//     树结构
export function role_tree(data){
  return service({url:'/api/role/getPermissionTree',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

//    新增
export function role_add(data){
  return service({url:'/api/role/addRole',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

//   获取某条编辑数据
export function role_compile(data){
  return service({url:'/api/role/getRoleById',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

//    编辑
export function role_compiles(data){
  return service({url:'/api/role/modifyRole',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 是否具有增加权限 
export function role_add_power(data){
  return service({url:'/api/role/check_add_permission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 是否具有编辑权限 
export function role_compile_power(data){
  return service({url:'/api/role/check_edit_permission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}




// 权限管理
export function right_index(data){
  return service({url:'/api/permission/getPermissionPageIndex',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
export function right(data){
  return service({url:'/api/permission/getPermissionPage',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//      父权限
export function right_father(data){
  return service({url:'/api/permission/getPermission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//      添加
export function right_add(data){
  return service({url:'/api/permission/addPermission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

//     编辑
export function right_compiles(data){
  return service({url:'/api/permission/modifyPermission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}


//    获取某个用户数据
export function right_compile(data){
  return service({url:'/api/permission/getPermissionById',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}


//     搜索
export function right_search(data){
  return service({url:'/api/permission/getPermissionPage',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

//     停用
export function right_stop(data){
  return service({url:'/api/permission/deletePermission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

//     启用
export function right_open(data){
  return service({url:'/api/permission/enable',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

// 是否具有增加权限 
export function right_add_power(data){
  return service({url:'/api/permission/check_add_permission',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}







// 字典管理
export function dict(data){
  return service({url:'/api/dict/getDict',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
export function dict_index(data){
  return service({url:'/api/dict/getDictIndex',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//   新增
export function dict_add(data){
  return service({url:'/api/dict/addDict',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//    编辑
export function dict_compiles(data){
  return service({url:'/api/dict/modifyDict',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 启用 
export function dict_open(data){
  return service({url:'/api/dict/enableDict',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 禁用 
export function dict_stop(data){
  return service({url:'/api/dict/deleteDict',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}







// 分润系统
export function profit(data){
  return service({url:'/api/profit_conf/get_profit_conf_index',method: 'POST', data})
}
// 渠道
export function fwglmedia(data){
  return service({url:'/api/profit_conf/get_operate_media_select',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 渠道
export function get_operate_media_select_by_source(data){
  return service({url:'/api/profit_conf/get_operate_media_select_by_source',method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//  搜索
export function profit_search(data){
  return service({url:'/api/profit_conf/get_profit_conf',method: 'POST', data})
}
//  添加
export function profit_add(data){
  return service({url:'/api/profit_conf/add_profit_conf',method: 'POST', data})
}
// 禁用
export function profit_stop(data){
  return service({url:'/api/profit_conf/del_profit_conf',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//  启用
export function profit_open(data){
  return service({url:'/api/profit_conf/enable_profit_conf',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 获取某条数据
export function profit_compile(data){
  return service({url:'/api/profit_conf/get_profit_conf_by_id',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 获取多条分润配置
export function get_profit_conf_update_info(data){
  return service({url:'/api/profit_conf/get_profit_conf_update_info',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//  编辑
export function profit_compiles(data){
  return service({url:'/api/profit_conf/update_profit_conf_by_id',method: 'POST', data})
}
//  审核
export function profit_audit(data){
  return service({url:'/api/profit_conf/examine_profit_conf_by_id',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//   调整
export function profit_adjust(data){
  return service({url:'/api/profit_conf/adjustment_profit_conf_by_id',method: 'POST', data})
}
//  获取调整某项数据
export function profit_adjust_compile(data){
  return service({url:'/api/profit_conf/get_profit_conf_by_id_adjustment',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 渠道
export function sjhzmedia(data){
  return service({url:'/api/summary/get_operate_media_select',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

// 上游账号下拉
export function source_selectfr(data){
  return service({url:'/api/profit_conf/source_select',method: 'POST', data})
}
// 数据源下拉
export function mtfrget_datasource_select(data){
  return service({url:'/api/profit_conf/get_datasource_select',method: 'POST', data})
}





// 渠道管理
// 获取运营负责渠道
export function channel(data){
  return service({url:'/api/media/get_operate_media_select',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 获取渠道信息 
export function channel_index(data){
  return service({url:'/api/media/get_media_index',method: 'POST', data})
}
// 搜索 
export function channel_search(data){
  return service({url:'/api/media/get_media',method: 'POST', data})
}
// 禁用
export function channel_stop(data){
  return service({url:'/api/media/delete_media',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//  启用
export function channel_open(data){
  return service({url:'/api/media/enable_media',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//  添加
export function channel_add(data){
  return service({url:'/api/media/create_media',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 数据源下拉
export function qdget_datasource_select(data){
  return service({url:'/api/media/get_datasource_select',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 获取某条数据
export function channel_compile(data){
  return service({url:'/api/media/get_media_by_id',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
//  编辑
export function channel_compiles(data){
  return service({url:'/api/media/modify_media',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}





// 数据发布
// 新增发布日期
export function issue_add(data){
  return service({url:'/api/release_time/add_release_time',method: 'POST', data})
}
export function issue_index(data){
  return service({url:'/api/release_time/get_release_time_index',method: 'POST', data})
}
// 获取发布日期
export function issue_compile(data){
  return service({url:'/api/release_time/get_release_time',method: 'POST', data})
}






// 首页 用户汇总数据
export function Daily(data) {
  return service({url:"/api/summary/summary",method: 'POST', data});
}


// 小时数据
export function useLog(data) {
  return service({url:"/api/report/hourly",method: 'POST', data});
}
// 数据报表  查询
export function Inquire(data) {
  return service({url:"/api/report/daily",method: 'POST', data});
}
// 广告位下拉
export function Domain(data) {
  return service({url:"/api/report/slot_select",method: 'POST', data});
}
// 国家下拉
export function State(data) {
  return service({url:"/api/report/country_select",method: 'POST', data});
}
// 媒体下拉
export function Media(data) {
  return service({url:"/api/report/domain_select",method: 'POST', data});
}

// 发布单条数据 
export function report_issue(data){
  return service({url:'/api/report/release_daily_data_by_id',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

// 取消单条数据 
export function report_cancel(data){
  return service({url:'/api/report/cancel_daily_data_release_by_id',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

// 批量发布数据 
export function report_issues(data){
  return service({url:'/api/report/batch_release_daily_data',method: 'POST', data})
}

// 取消批量发布数据 
export function report_issues_cancel(data){
  return service({url:'/api/report/cancel_batch_release_daily_data',method: 'POST', data})
}

// 通道下拉
export function channel_select(data){
  return service({url:'/api/report/channel_select',method: 'POST', data})
}


// 大运营  查询
export function report_Inquire(data) {
  return service({url:"/api/report_first/daily_first",method: 'POST', data});
}
// 发布单条数据 
export function operation_issue(data){
  return service({url:'/api/report_first/release_daily_data_by_id_first',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}
// 取消单条数据 
export function operation_cancel(data){
  return service({url:'/api/report_first/cancel_daily_data_release_by_id_first',method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}})
}

// 批量发布数据 
export function operation_issues(data){
  return service({url:'/api/report_first/batch_release_daily_data_first',method: 'POST', data})
}
// 
export function report_period(data){
  return service({url:'/api/report/period',method: 'POST', data})
}
// 导出
export function daily_excel(data){
  return service({url:'/api/report/daily_excel',method: 'POST', data, responseType: 'blob'})
}







// 分润方初始查询
export function profit_side(data) {
  return service({url:"/api/profit_assignee/get_profit_assignee_index",method: 'POST', data});
}
// 查询
export function profit_side_search(data) {
  return service({url:"/api/profit_assignee/get_profit_assignee",method: 'POST', data});
}
// 新增
export function profit_side_add(data) {
  return service({url:"/api/profit_assignee/add_profit_assignee",method: 'POST', data});
}
// 根据id获取某一项
export function profit_side_compile(data) {
  return service({url:"/api/profit_assignee/get_profit_assigneeId_by_id",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 修改
export function profit_side_compiles(data) {
  return service({url:"/api/profit_assignee/update_profit_assigneeId_by_id",method: 'POST', data});
}
// 禁用
export function profit_side_stop(data) {
  return service({url:"/api/profit_assignee/del_profit_assignee",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 启用
export function profit_side_open(data) {
  return service({url:"/api/profit_assignee/enable_profit_assignee",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}




// 返利方初始查询
export function rebate_side(data) {
  return service({url:"/api/rebate_assignee/get_rebate_assignee_index",method: 'POST', data});
}
// 查询
export function rebate_side_search(data) {
  return service({url:"/api/rebate_assignee/get_rebate_assignee",method: 'POST', data});
}
// 新增
export function rebate_side_add(data) {
  return service({url:"/api/rebate_assignee/add_rebate_assignee",method: 'POST', data});
}
// 根据id获取某一项
export function rebate_side_compile(data) {
  return service({url:"/api/rebate_assignee/get_rebate_assigneeId_by_id",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 修改
export function rebate_side_compiles(data) {
  return service({url:"/api/rebate_assignee/update_rebate_assigneeId_by_id",method: 'POST', data});
}
// 禁用
export function rebate_side_stop(data) {
  return service({url:"/api/rebate_assignee/del_rebate_assignee",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 启用
export function rebate_side_open(data) {
  return service({url:"/api/rebate_assignee/enable_rebate_assignee",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}




// 获取渠道资源 
export function profit_channel(data) {
  return service({url:"/api/profit_assignee_conf/get_data_sources_select",method: 'POST', data});
}

// 获取分润方 
export function profit_person(data) {
  return service({url:"/api/profit_assignee_conf/get_profit_assignee_select",method: 'POST', data});
}

// 获取运营负责渠道 
export function operation_channel(data) {
  return service({url:"/api/profit_assignee_conf/get_operate_media_select",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}

// 初始 
export function profit_person_index(data) {
  return service({url:"/api/profit_assignee_conf/get_profit_assignee_conf_index",method: 'POST', data});
}

// 获取分润方配置 搜索
export function profit_person_search(data) {
  return service({url:"/api/profit_assignee_conf/get_profit_assignee_conf",method: 'POST', data});
}




// 财务报表 
export function careful_finance(data) {
  return service({url:"/api/rpt_financial/get_rpt_financial",method: 'POST', data});
}


// 渠道下拉
export function qd(data) {
  return service({url:"/api/slot_manage/get_media_select",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}

// 列表
export function gg_data(data) {
  return service({url:"/api/slot_manage/get_slot",method: 'POST', data});
}
// 广告添加媒体
export function gg_mt(data) {
  return service({url:"/api/slot_manage/add_media_to_slot",method: 'POST', data});
}

// 主渠道下拉
export function get_media_select_by_source(data) {
  return service({url:"/api/slot_manage/get_media_select_by_source",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}





// 系统时区 1香港两点  2太平洋六点
export function time_zone(data) {
  return service({url:"/api/time_zone/get_time_zone_code",method: 'POST', data: qs.stringify(data)});
}
// 数据发布时间
export function get_release_time(data) {
  return service({url:"/api/time_zone/get_release_time",method: 'POST', data: qs.stringify(data)});
}

// 获取数据可发布时间
export function get_zone_time_now(data) {
  return service({url:"/api/time_zone/get_zone_time_now",method: 'POST', data: qs.stringify(data)});
}





// 上传文件
export function upLoad(data) {
  return service({url:"/api/contract/fileUpLoad/upLoad",method: 'POST', data, headers: {'Content-Type':'multipart/form-data'}});
}
// 文件预览
export function preview(data) {
  return service({url:"/api/contract/getFilePrefix",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}

// 文件预览
export function previews(data) {  
  return service({url:`/api/contract/fileUpLoad/preview?fileName=${data.fileName}&key=${encodeURIComponent(data.key)}`,responseType: 'blob', method: 'GET'});
}

//  新建
export function addContract(data) {
  return service({url:"/api/contract/addContract",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
//  停用
export function deleteContract(data) {
  return service({url:"/api/contract/deleteContract",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
//  启用
export function enable(data) {
  return service({url:"/api/contract/enable",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
//  修改
export function modifyContract(data) {
  return service({url:"/api/contract/modifyContract",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
//  查询
export function getContract(data) {
  return service({url:"/api/contract/getContract",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}



// 获取用户日数据
export function period(data) {
  return service({url:"/api/summary/period",method: 'POST', data});
}












// 常规查询
export function get_data(data) {
  return service({url:"/api/quality_general_query/get_data",method: 'POST', data});
}
// 详情
export function get_data_info(data) {
  return service({url:"/api/quality_general_query/get_data_info",method: 'POST', data});
}
// 获取运营渠道
export function get_operate_media_select(data) {
  return service({url:"/api/quality_general_query/get_operate_media_select",method: 'POST', data});
}
// 获取媒体下拉
export function domain_select(data) {
  return service({url:"/api/quality_general_query/domain_select",method: 'POST', data});
}
// 游戏账号下拉
export function source_select(data) {
  return service({url:"/api/quality_general_query/source_select",method: 'POST', data});
}
// 国家
export function cgcxcountry_select(data) {
  return service({url:"/api/quality_general_query/country_select",method: 'POST', data});
}
// 明细
export function get_data_detail(data) {
  return service({url:"/api/quality_general_query/get_data_detail",method: 'POST', data});
}
// 明细导出
export function get_data_detail_excel(data){
  return service({url:'/api/quality_general_query/get_data_detail_excel',method: 'POST', data, responseType: 'blob'})
}






// 游戏账号下拉
export function sd_source_select(data) {
  return service({url:"/api/depth_analyse/source_select",method: 'POST', data});
}
// 渠道下拉 
export function sd_get_operate_media_select(data) {
  return service({url:"/api/depth_analyse/get_operate_media_select",method: 'POST', data});
}
// 媒体下拉
export function sd_domain_select(data) {
  return service({url:"/api/depth_analyse/domain_select",method: 'POST', data});
}
// 广告下拉
export function sd_slot_select(data) {
  return service({url:"/api/depth_analyse/slot_select",method: 'POST', data});
}
// 国家下拉
export function sd_country_select(data) {
  return service({url:"/api/depth_analyse/country_select",method: 'POST', data});
}
// 获取历史数据
export function sd_get_data(data) {
  return service({url:"/api/depth_analyse/get_data",method: 'POST', data});
}
// 获取历史详情
export function sd_get_data_info(data) {
  return service({url:"/api/depth_analyse/get_data_info",method: 'POST', data});
}
// 进度条
export function schedule(data) {
  return service({url:"/api/depth_analyse/schedule",method: 'POST', data});
}

// 获取广告位可修改的渠道
export function modifiable_slot_media(data) {
  return service({url:"/api/slot_manage/modifiable_slot_media",method: 'POST', data});
}

// 广告位修改
export function update_media_slot(data) {
  return service({url:"/api/slot_manage/update_media_slot",method: 'POST', data});
}
// 广告位新增
export function add_slot_media(data) {
  return service({url:"/api/slot_manage/add_slot_media",method: 'POST', data});
}
// 数据源下拉
export function get_datasource_select(data) {
  return service({url:"/api/slot_manage/get_datasource_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}

// 渠道
export function sjbgmedia(data) {
  return service({url:"/api/report/get_operate_media_select",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}


// 渠道
export function sjbbmedia(data) {
  return service({url:"/api/report_first/get_operate_media_select",method: 'POST', data: qs.stringify(data), headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}

// 数据源权限 
export function get_all_data_source(data) {
  return service({url:"/api/user/get_all_data_source",method: 'POST', data});
}



// 数据源查询
export function get_datasource(data) {
  return service({url:"/api/data_source/get_datasource",method: 'POST', data});
}

// 数据源下拉查询
export function get_datasource_index(data) {
  return service({url:"/api/data_source/get_datasource_index",method: 'POST', data});
}
// 修改数据源
export function edit_datasource(data) {
  return service({url:"/api/data_source/edit_datasource",method: 'POST', data});
}


// 域名核减
// 上游账号下拉
export function source_selectym(data) {
  return service({url:"/api/cut_after_examination/source_select",method: 'POST', data});
}
// 渠道下拉
export function get_operate_media_select_by_sourceym(data) {
  return service({url:"/api/cut_after_examination/get_operate_media_select_by_source",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 域名下拉
export function domain_selectym(data) {
  return service({url:"/api/cut_after_examination/domain_select",method: 'POST', data});
}
// 搜索
export function get_dataym(data) {
  return service({url:"/api/cut_after_examination/get_data",method: 'POST', data});
}
// 导出
export function get_data_excel(data){
  return service({url:'/api/cut_after_examination/get_data_excel',method: 'POST', data, responseType: 'blob'})
}



// 点击核减
// 上游账号下拉
export function source_selectdj(data) {
  return service({url:"/api/deduction_detail/source_select",method: 'POST', data});
}
// 渠道下拉
export function get_operate_media_select_by_sourcedj(data) {
  return service({url:"/api/deduction_detail/get_operate_media_select_by_source",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 域名下拉
export function domain_selectdj(data) {
  return service({url:"/api/deduction_detail/domain_select",method: 'POST', data});
}
// 搜索
export function get_datadj(data) {
  return service({url:"/api/deduction_detail/get_data",method: 'POST', data});
}
// 导出
export function get_data_exceldj(data){
  return service({url:'/api/deduction_detail/get_data_excel',method: 'POST', data, responseType: 'blob'})
}







// 广告源管理
// 数据源下拉
export function get_source_select(data) {
  return service({url:"/api/ad_source/get_source_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 广告源厂商
export function get_adsource_manufacturer_select(data) {
  return service({url:"/api/ad_source/get_adsource_manufacturer_select",method: 'POST', data});
}
// 广告源类型
export function get_adsource_type_select(data) {
  return service({url:"/api/ad_source/get_adsource_type_select",method: 'POST', data});
}
// 广告源列表
export function get_source(data) {
  return service({url:"/api/ad_source/get_source",method: 'POST', data});
}
// 添加
export function add_source(data) {
  return service({url:"/api/ad_source/add_source",method: 'POST', data});
}
// 修改
export function edit_source(data) {
  return service({url:"/api/ad_source/edit_source",method: 'POST', data});
}
// 开启关闭
export function change_status(data) {
  return service({url:"/api/ad_source/change_status",method: 'POST', data});
}




// 广告源媒体
// 数据源下拉
export function ggymtget_datasource_select(data) {
  return service({url:"/api/ad_source_domain_report/get_datasource_select",method: 'POST', data});
}
// 渠道下拉
export function ggyqdget_media_select_by_source(data) {
  return service({url:"/api/ad_source_domain_report/get_media_select_by_source",method: 'POST', data});
}
// 媒体下拉
export function ggymtdomain_select(data) {
  return service({url:"/api/ad_source_domain_report/domain_select",method: 'POST', data});
}
// 国家下拉
export function ggygjcountry_select(data) {
  return service({url:"/api/ad_source_domain_report/country_select",method: 'POST', data});
}
// 查询
export function ggycxdomain_report(data) {
  return service({url:"/api/ad_source_domain_report/domain_report",method: 'POST', data});
}
// 小时数据
export function ggyxshourly(data) {
  return service({url:"/api/ad_source_domain_report/hourly",method: 'POST', data});
}
// 趋势图
export function ggyqsttrend_chart(data) {
  return service({url:"/api/ad_source_domain_report/trend_chart",method: 'POST', data});
}
// 导出
export function domain_report_excel(data){
  return service({url:'/api/ad_source_domain_report/domain_report_excel',method: 'POST', data, responseType: 'blob'})
}



// 广告源日数据
// 数据源下拉
export function ggyrget_datasource_select(data) {
  return service({url:"/api/ad_source_daily_report/get_datasource_select",method: 'POST', data});
}
// 国家下拉
export function ggyrcountry_select(data) {
  return service({url:"/api/ad_source_daily_report/country_select",method: 'POST', data});
}
// 查询
export function ggyrdaily_report(data) {
  return service({url:"/api/ad_source_daily_report/daily_report",method: 'POST', data});
}
// 小时数据
export function ggyrhourly(data) {
  return service({url:"/api/ad_source_daily_report/hourly",method: 'POST', data});
}
// 趋势图
export function ggyrtrend_chart(data) {
  return service({url:"/api/ad_source_daily_report/trend_chart",method: 'POST', data});
}


// 小时数据对比
// 数据源下拉
export function xssjdbget_datasource_select(data) {
  return service({url:"/api/ad_source_data_contrast/get_datasource_select",method: 'POST', data});
}
// 渠道下拉
export function xssjdbget_media_select_by_source(data) {
  return service({url:"/api/ad_source_data_contrast/get_media_select_by_source",method: 'POST', data});
}
// 媒体下拉
export function xssjdbdomain_select(data) {
  return service({url:"/api/ad_source_data_contrast/domain_select",method: 'POST', data});
}
// 国家下拉
export function xssjdbcountry_select(data) {
  return service({url:"/api/ad_source_data_contrast/country_select",method: 'POST', data});
}
// 查询小时数据
export function xssjdbhourly(data) {
  return service({url:"/api/ad_source_data_contrast/hourly",method: 'POST', data});
}


// 广告源详细数据
// 数据源下拉
export function xxsjget_datasource_select(data) {
  return service({url:"/api/ad_source_detail_data/get_datasource_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 搜索
export function xxsjsummary(data) {
  return service({url:"/api/ad_source_detail_data/summary",method: 'POST', data});
}
// 预估收益
export function xxsjsummary_income_bulletin(data) {
  return service({url:"/api/ad_source_detail_data/summary_income_bulletin",method: 'POST', data});
}






// 广告源数据简报
// 数据源下拉
export function sjjbget_datasource_select(data) {
  return service({url:"/api/ad_source_data_bulletin/get_datasource_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 搜索
export function sjjbsummary(data) {
  return service({url:"/api/ad_source_data_bulletin/summary",method: 'POST', data});
}
// 预估收益
export function sjjbsummary_income_bulletin(data) {
  return service({url:"/api/ad_source_data_bulletin/summary_income_bulletin",method: 'POST', data});
}






// 站点信息
// 数据源
export function zdxxget_datasource_select(data) {
  return service({url:"/api/site/get_datasource_select",method: 'POST', data});
}
// 渠道下拉
export function zdxxget_media_select_by_source(data) {
  return service({url:"/api/site/get_media_select_by_source",method: 'POST', data});
}
// 站点类型下拉
export function zdxxget_site_type_select(data) {
  return service({url:"/api/site/get_site_type_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 广告源列表
export function zdxxget_site(data) {
  return service({url:"/api/site/get_site",method: 'POST', data});
}
// 添加站点
export function zdxxinsert_site(data) {
  return service({url:"/api/site/insert_site",method: 'POST', data});
}
// 修改站点
export function zdxxupdate_site(data) {
  return service({url:"/api/site/update_site",method: 'POST', data});
}






// 域名数据查询
// 数据源下拉
export function ymsjget_datasource_select(data) {
  return service({url:"/api/site_data_report/get_datasource_select",method: 'POST', data});
}
// 域名下拉
export function ymsjdomain_select(data) {
  return service({url:"/api/site_data_report/domain_select",method: 'POST', data});
}
// 国家下拉
export function ymsjcountry_select(data) {
  return service({url:"/api/site_data_report/country_select",method: 'POST', data});
}
// 查询
export function ymsjdomain_report(data) {
  return service({url:"/api/site_data_report/domain_report",method: 'POST', data});
}
// 趋势图
export function ymsjtrend_chart(data) {
  return service({url:"/api/site_data_report/trend_chart",method: 'POST', data});
}






// 站点域名变更记录
// 数据源下拉
export function bgjlget_datasource_select(data) {
  return service({url:"/api/site_update_log/get_datasource_select",method: 'POST', data});
}
// 渠道下拉
export function bgjlget_media_select_by_source(data) {
  return service({url:"/api/site_update_log/get_media_select_by_source",method: 'POST', data});
}
// 管理员下拉
export function bgjlget_operate_select_by_source(data) {
  return service({url:"/api/site_update_log/get_operate_select_by_source",method: 'POST', data});
}
// 查询
export function bgjlget_site_update_log(data) {
  return service({url:"/api/site_update_log/get_site_update_log",method: 'POST', data});
}






// 站点域名质控
// 上游账号
export function ymzksource_select(data) {
  return service({url:"/api/site_domain_quality_control/source_select",method: 'POST', data});
}

// 渠道
export function ymzkget_operate_media_select(data) {
  return service({url:"/api/site_domain_quality_control/get_operate_media_select",method: 'POST', data});
}

// 媒体
export function ymzkdomain_select(data) {
  return service({url:"/api/site_domain_quality_control/domain_select",method: 'POST', data});
}

// 广告位
export function ymzkslot_select(data) {
  return service({url:"/api/site_domain_quality_control/slot_select",method: 'POST', data});
}

// 国家
export function ymzkcountry_select(data) {
  return service({url:"/api/site_domain_quality_control/country_select",method: 'POST', data});
}

// 查询
export function ymzkget_data(data) {
  return service({url:"/api/site_domain_quality_control/get_data",method: 'POST', data});
}

// 查询进度
export function ymzkschedule(data) {
  return service({url:"/api/site_domain_quality_control/schedule",method: 'POST', data});
}

// 详情
export function ymzkget_data_info(data) {
  return service({url:"/api/site_domain_quality_control/get_data_info",method: 'POST', data});
}







// 站点域名数据对比
// 广告源
// 国家
export function ymdbcountry_select(data) {
  return service({url:"/api/site_data_contrast/country_select",method: 'POST', data});
}
// 查询
export function ymdbhourly(data) {
  return service({url:"/api/site_data_contrast/hourly",method: 'POST', data});
}








// 域名整体核减
// 上游账号下拉
export function ymzthjsource_select(data) {
  return service({url:"/api/domain_deduction/source_select",method: 'POST', data});
}
// 渠道下拉
export function ymzthjget_operate_media_select_by_source(data) {
  return service({url:"/api/domain_deduction/get_operate_media_select_by_source",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 域名下拉
export function ymzthjdomain_select(data) {
  return service({url:"/api/domain_deduction/domain_select",method: 'POST', data});
}
// 查询
export function ymzthjget_data(data) {
  return service({url:"/api/domain_deduction/get_data",method: 'POST', data});
}









// 数据处理日志
// 数据源
export function clrzget_datasource_select(data) {
  return service({url:"/api/rpt_job_log/get_datasource_select",method: 'POST', data});
}
// 渠道
export function clrzget_media_select_by_source(data) {
  return service({url:"/api/rpt_job_log/get_media_select_by_source",method: 'POST', data});
}
// 查询
export function get_job_log(data) {
  return service({url:"/api/rpt_job_log/get_job_log",method: 'POST', data});
}
// 数据重跑
export function patch_job_by_id(data) {
  return service({url:"/api/rpt_job_log/patch_job_by_id",method: 'POST', data});
}
// 添加重跑任务
export function add_patch_job(data) {
  return service({url:"/api/rpt_job_log/add_patch_job",method: 'POST', data});
}
// 批量重跑数据
export function batch_patch_job(data) {
  return service({url:"/api/rpt_job_log/batch_patch_job",method: 'POST', data});
}



// 操作日志
// 操作人下拉
export function get_operate_user_select(data) {
  return service({url:"/api/operation_log/get_operate_user_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 操作日志搜索
export function get_operation_log(data) {
  return service({url:"/api/operation_log/get_operation_log",method: 'POST', data});
}
// 模块下拉
export function get_operation_log_module_code_select(data) {
  return service({url:"/api/operation_log/get_operation_log_module_code_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 操作类型下拉
export function get_operation_log_type_select(data) {
  return service({url:"/api/operation_log/get_operation_log_type_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}




// 日常问题
// 账号下拉
export function rcwtsource_select(data) {
  return service({url:"/api/daily_prob_query/source_select",method: 'POST', data});
}
// 渠道下拉
export function rcwtget_operate_media_select(data) {
  return service({url:"/api/daily_prob_query/get_operate_media_select",method: 'POST', data});
}
// 媒体下拉
export function rcwtdomain_select(data) {
  return service({url:"/api/daily_prob_query/domain_select",method: 'POST', data});
}
// 获取问题数据
export function rcwtget_data(data) {
  return service({url:"/api/daily_prob_query/get_data",method: 'POST', data});
}
// 获取问题数据明细
export function rcwtget_data_detail(data) {
  return service({url:"/api/daily_prob_query/get_data_detail",method: 'POST', data});
}
// 修改问题数据处理结果
export function rcwtmodify_data_status(data) {
  return service({url:"/api/daily_prob_query/modify_data_status",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 问题数据补核
export function replenish_data(data) {
  return service({url:"/api/daily_prob_query/replenish_data",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}



// 数据源
export function tdget_datasource_select(data) {
  return service({url:"/api/channel_manage/get_datasource_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 渠道
export function tdget_media_select(data) {
  return service({url:"/api/channel_manage/get_media_select",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 渠道
export function tdget_media_select_by_source(data) {
  return service({url:"/api/channel_manage/get_media_select_by_source",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 可修改的渠道，与部分可修改的渠道
export function tdmodifiable_slot_media(data) {
  return service({url:"/api/channel_manage/modifiable_slot_media",method: 'POST', data, headers: {'Content-Type':'application/x-www-form-urlencoded'}});
}
// 查询
export function tdget_channel(data) {
  return service({url:"/api/channel_manage/get_channel",method: 'POST', data});
}

// 添加广告位
export function tdadd_channel_media(data) {
  return service({url:"/api/channel_manage/add_channel_media",method: 'POST', data});
}
// 
export function tdadd_media_to_channel(data) {
  return service({url:"/api/channel_manage/add_media_to_channel",method: 'POST', data});
}
// 
export function tddel_media_channel(data) {
  return service({url:"/api/channel_manage/del_media_channel",method: 'POST', data});
}
// 
export function tdupdate_media_channel(data) {
  return service({url:"/api/channel_manage/update_media_channel",method: 'POST', data});
}