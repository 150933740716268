import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
    // redirect: "/manage",
    children: [
      {
        path: "/manage",
        name: "manage",
        redirect: "/manage/user",
        meta: {
          title: "系统管理",
          key: 0,
        },
        component: () =>
          import(
            /* webpackChunkName: "manageView" */ "../views/Manage/manageView.vue"
          ),
        children: [
          {
            path: "/manage/user",
            name: "manage-user",
            meta: {
              title: "用户管理",
              index: 2,
            },
            component: () =>
              import(
                /* webpackChunkName: "userView" */ "../views/Manage/userView.vue"
              ),
          },
          {
            path: "/manage/role",
            name: "manage-role",
            meta: {
              title: "角色管理",
              index: 3,
            },
            component: () =>
              import(
                /* webpackChunkName: "roleView" */ "../views/Manage/roleView.vue"
              ),
          },
          {
            path: "/manage/right",
            name: "manage-right",
            meta: {
              title: "权限管理",
              index: 4,
            },
            component: () =>
              import(
                /* webpackChunkName: "rightView" */ "../views/Manage/rightView.vue"
              ),
          },
          {
            path: "/manage/dict",
            name: "manage-dict",
            meta: {
              title: "字典管理",
              index: 5,
            },
            component: () =>
              import(
                /* webpackChunkName: "dictView" */ "../views/Manage/dictView.vue"
              ),
          },
          {
            path: "/manage/regulate",
            name: "manage-regulate",
            meta: {
              title: "渠道管理",
              index: 73,
            },
            component: () =>
              import(
                /* webpackChunkName: "regulateView" */ "../views/Manage/regulateView.vue"
              ),
          },
        ],
      },
      {
        path: "/profit",
        name: "profit",
        redirect: "/profit/regulate",
        meta: {
          title: "分润管理",
          key: 1,
        },
        component: () =>
          import(
            /* webpackChunkName: "profitView" */ "../views/profit/profitView.vue"
          ),
        children: [
          {
            path: "/profit/regulate",
            name: "profit-regulate",
            meta: {
              title: "分润配置",
              index: 67,
            },
            component: () =>
              import(
                /* webpackChunkName: "regulatesView" */ "../views/profit/regulatesView.vue"
              ),
          },
        ],
      },
      {
        path: "issue",
        name: "issue",
        redirect: "/issue/dataissue",
        meta: {
          title: "数据发布",
          key: 2,
        },
        component: () =>
          import(
            /* webpackChunkName: "issueView" */ "../views/Issue/issueView.vue"
          ),
        children: [
          {
            path: "/issue/dataissue",
            name: "issue-dataissue",
            meta: {
              title: "发布配置",
              index: 78,
            },
            component: () =>
              import(
                /* webpackChunkName: "dataissueView" */ "../views/Issue/dataissueView.vue"
              ),
          },
        ],
      },
      {
        path: "channel",
        name: "channel",
        redirect: "/channel/collect",
        meta: {
          title: "数据发布",
          key: 3,
        },
        component: () =>
          import(
            /* webpackChunkName: "channelView" */ "../views/Channel/channelView.vue"
          ),
        children: [
          {
            path: "/channel/collect",
            name: "channel-collect",
            meta: {
              title: "发布配置",
              index: 10,
            },
            component: () =>
              import(
                /* webpackChunkName: "collectView" */ "../views/Channel/collectView.vue"
              ),
          },
          {
            path: "/channel/data",
            name: "channel-data",
            meta: {
              title: "发布配置",
              index: 11,
            },
            component: () =>
              import(
                /* webpackChunkName: "dataView" */ "../views/Channel/dataView.vue"
              ),
          },
        ],
      },
      {
        path: "finance",
        name: "finance",
        redirect: "/finance/profit",
        meta: {
          title: "分润系统",
          key: 4,
        },
        component: () =>
          import(
            /* webpackChunkName: "financeView" */ "../views/Finance/financeView.vue"
          ),
        children: [
          {
            path: "/finance/profit",
            name: "finance-profit",
            meta: {
              title: "分润方",
              index: 10,
            },
            component: () =>
              import(
                /* webpackChunkName: "profitView" */ "../views/Finance/profitView.vue"
              ),
          },
          {
            path: "/finance/rebate",
            name: "finance-rebate",
            meta: {
              title: "返利方",
              index: 11,
            },
            component: () =>
              import(
                /* webpackChunkName: "rebateView" */ "../views/Finance/rebateView.vue"
              ),
          },
          {
            path: "/finance/profitDivide",
            name: "finance-profitDivide",
            meta: {
              title: "分润方",
              index: 12,
            },
            component: () =>
              import(
                /* webpackChunkName: "profit_divideView" */ "../views/Finance/profit_divideView.vue"
              ),
          },
          {
            path: "/finance/rebateDivide",
            name: "finance-rebateDivide",
            meta: {
              title: "返利方",
              index: 13,
            },
            component: () =>
              import(
                /* webpackChunkName: "rebate_divideView" */ "../views/Finance/rebate_divideView.vue"
              ),
          },
          {
            path: "/finance/carefulFinance",
            name: "finance-carefulFinance",
            meta: {
              title: "细分财务报表",
              index: 14,
            },
            component: () =>
              import(
                /* webpackChunkName: "careful_financeView" */ "../views/Finance/careful_financeView.vue"
              ),
          },
        ],
      },
      {
        path: "operation",
        name: "operation",
        redirect: "/operation/statement",
        meta: {
          title: "运营管理",
          key: 5,
        },
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/Operation/operation.vue"
          ),
        children: [
          {
            path: "/operation/statement",
            name: "operation-statement",
            meta: {
              title: "数据报表",
              index: 10,
            },
            component: () =>
              import(
                /* webpackChunkName: "operationView" */ "../views/Operation/operationView.vue"
              ),
          },
        ],
      },
      {
        path: "/admanage",
        name: "admanage",
        component: () =>
          import(
            /* webpackChunkName: "admanageView" */ "../views/AdManage/admanageView.vue"
          ),
      },
      {
        path: "/channelManage",
        name: "channelManage",
        component: () =>
          import(
            /* webpackChunkName: "admanageView" */ "../views/AdManage/channelManage.vue"
          ),
      },
      {
        path: "/dataSource",
        name: "dataSource",
        component: () =>
          import(
            /* webpackChunkName: "dataSourceView" */ "../views/Channel/dataSourceView.vue"
          ),
      },
      {
        path: "/contract",
        name: "contract",
        component: () =>
          import(
            /* webpackChunkName: "contractView" */ "../views/contract/contractView.vue"
          ),
      },
      {
        path: "/advertising",
        name: "advertising",
        component: () =>
          import(
            /* webpackChunkName: "advertisingView" */ "../views/advertising/advertisingView.vue"
          ),
      },
      {
        path: "/comparison",
        name: "comparison",
        component: () =>
          import(
            /* webpackChunkName: "notFound" */ "../views/Comparison/comparisonView.vue"
          ),
      },
      {
        path: "/qualityGeneralQuery",
        name: "qualityGeneralQuery",
        component: () =>
          import(
            /* webpackChunkName: "qualityGeneralQuery" */ "../views/qualityControl/qualityGeneralQuery.vue"
          ),
      },
      {
        path: "/depthAnalyse",
        name: "depthAnalyse",
        component: () =>
          import(
            /* webpackChunkName: "depthAnalyse" */ "../views/qualityControl/depthAnalyse.vue"
          ),
      },
      {
        path: "/cutAfterExamination",
        name: "cutAfterExamination",
        component: () =>
          import(
            /* webpackChunkName: "cutAfterExamination" */ "../views/DeductionDetails/cutAfterExamination.vue"
          ),
      },
      {
        path: "/deductionDetail",
        name: "deductionDetail",
        component: () =>
          import(
            /* webpackChunkName: "deductionDetail" */ "../views/DeductionDetails/deductionDetail.vue"
          ),
      },
      {
        path: "/adSource",
        name: "adSource",
        component: () =>
          import(
            /* webpackChunkName: "adSource" */ "../views/dataSource/ggSource.vue"
          ),
      },
      {
        path: "/adMedia",
        name: "adMedia",
        component: () =>
          import(
            /* webpackChunkName: "adMedia" */ "../views/dataSource/ggMedia.vue"
          ),
      },
      {
        path: "/adContrast",
        name: "adContrast",
        component: () =>
          import(
            /* webpackChunkName: "adContrast" */ "../views/dataSource/ggContrast.vue"
          ),
      },
      {
        path: "/adDaydata",
        name: "adDaydata",
        component: () =>
          import(
            /* webpackChunkName: "adDaydata" */ "../views/dataSource/ggDaydata.vue"
          ),
      },
      {
        path: "/adBulletin",
        name: "adBulletin",
        component: () =>
          import(
            /* webpackChunkName: "adDaydata" */ "../views/dataSource/ggBulletin.vue"
          ),
      },
      {
        path: "/adDetailData",
        name: "adDetailData",
        component: () =>
          import(
            /* webpackChunkName: "adDaydata" */ "../views/dataSource/ggDetailData.vue"
          ),
      },
      {
        path: "/siteInformation",
        name: "siteInformation",
        component: () =>
          import(
            /* webpackChunkName: "canpSource" */ "../views/canpymgl/siteInformation.vue"
          ),
      },
      {
        path: "/domainQuery",
        name: "domainQuery",
        component: () =>
          import(
            /* webpackChunkName: "domainQuery" */ "../views/canpymgl/domainQuery.vue"
          ),
      },
      {
        path: "/domainChange",
        name: "domainChange",
        component: () =>
          import(
            /* webpackChunkName: "domainChange" */ "../views/canpymgl/domainChange.vue"
          ),
      },
      {
        path: "/domainQuality",
        name: "domainQuality",
        component: () =>
          import(
            /* webpackChunkName: "domainQuality" */ "../views/canpymgl/domainQuality.vue"
          ),
      },
      {
        path: "/domainContrast",
        name: "domainContrast",
        component: () =>
          import(
            /* webpackChunkName: "domainContrast" */ "../views/canpymgl/domainContrast.vue"
          ),
      },
      {
        path: "/domainDeduction",
        name: "domainDeduction",
        component: () =>
          import(
            /* webpackChunkName: "domainDeduction" */ "../views/DeductionDetails/domainDeduction.vue"
          ),
      },
      {
        path: "/processingLogs",
        name: "processingLogs",
        component: () =>
          import(
            /* webpackChunkName: "processingLogs" */ "../views/Manage/processingLogs.vue"
          ),
      },
      {
        path: "/operationLog",
        name: "operationLog",
        component: () =>
          import(
            /* webpackChunkName: "operationLog" */ "../views/Manage/operationLog.vue"
          ),
      },
      {
        path: "/dailyProbQuery",
        name: "dailyProbQuery",
        component: () =>
          import(
            /* webpackChunkName: "dailyProbQuery" */ "../views/qualityControl/dailyProbQuery.vue"
          ),
      },
      {
        path: "/:pathMatch*",
        name: "notFound",
        component: () =>
          import(
            /* webpackChunkName: "notFound" */ "../views/NotFound/notFound.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/loginView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token");
  if (to.path !== "/") {
    if (token) {
      // NProgress.start()
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});
router.afterEach((to, from, next) => {
  // NProgress.done()
  if (to.meta.title) {
    document.title = `扬歌广告运营管理`;
  }
});
export default router;
