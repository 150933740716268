import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import * as echarts from 'echarts'
import './assets/font_4473603_ufobcr7z7ne/iconfont.css';

let currentVersion = localStorage.getItem('appVersion') || '1.0.0';

// 应用启动时立即检查一次版本
fetch('/version.json?t=' + Date.now())
  .then(res => res.json())
  .then(data => {
    const latestVersion = data.version;
    if (latestVersion !== currentVersion) {
      localStorage.setItem('appVersion', latestVersion); // 存储最新版本
      window.location.reload(true); // 立即刷新
    }
  });

// 轮询检查版本更新
setInterval(() => {
  fetch('/version.json?t=' + Date.now())
    .then(res => res.json())
    .then(data => {
      const latestVersion = data.version;
      const savedVersion = localStorage.getItem('appVersion');

      if (latestVersion !== savedVersion) {
        // if (confirm('发现新版本，点击确定立即更新！')) {
          localStorage.setItem('appVersion', latestVersion);
          window.location.reload(true);
        // }
      }
    });
}, 3000); // 每60秒检查一次

const app = createApp(App)
app.echarts = echarts
app.use(Antd).use(store).use(router).provide('axios',axios).mount('#app')
